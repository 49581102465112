import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Can, Icon, useAuth, Input } from '@avtjs/react-components';

import InvitationList from './InvitationList';
import Heading from '../../../../Heading';
import AddInvitationModal from './AddInvitationModal';
import PermissionLegend from '../../../../PermissionLegend';
import SimpleModal from '../../../../SimpleModal';
import RoleSelect from '../../../../RoleSelect';
import { getUiSchema, getInvitationSchema } from '../schema';

import {
  requestMembers,
  isLoadingMembers,
  isLoadingInvitations,
  requestInvitations,
  createInvitations,
  updateInvitation,
  deleteInvitation,
  getInvitations,
  getMembers,
} from '../../../../../bundles/auth';
import { requestUsers, getUsers, getUsersLoading } from '../../../../../bundles/ad';
import { getSites } from '../../../../../bundles/sites';

const InvitationsTab = ({ siteId, wizardType }) => {
  const dispatch = useDispatch();
  const { realm } = useAuth();
  const loadingMembers = useSelector(isLoadingMembers);
  const invitations = useSelector(getInvitations);
  const loadingInvitations = useSelector(isLoadingInvitations);
  const loadingUsers = useSelector(getUsersLoading);
  const tenantUsers = useSelector(getUsers);
  const sites = useSelector(getSites);
  const orgMembers = useSelector((state) => getMembers(state, `org/${realm}`));
  const siteMembers = useSelector((state) => getMembers(state, `site/${siteId}`));

  const [siteFormattedInvitations, setSiteFormattedInvitations] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [displayAddInvitationModal, setDisplayAddInvitationModal] = useState(false);
  const [displayRoleDescriptions, setDisplayRoleDescriptions] = useState(false);
  const [inviteFormData, setInviteFormData] = useState(null);
  const [createdEmails, setCreatedEmails] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    dispatch(requestUsers());
    dispatch(requestInvitations(realm));
  }, []);

  useEffect(() => {
    if (!loadingMembers) {
      setInitialized(true);
    }
  }, [loadingMembers]);

  const validNonInvitedNonSiteTenantUsers = useMemo(() => {
    if (tenantUsers && siteMembers && invitations) {
      const siteUsers = siteMembers
        .reduce(
          (acc, member) => [
            ...acc,
            ...member.connected.map((userEntity) => userEntity.split('/')[1]),
          ],
          []
        )
        .map((userId) => {
          const user = tenantUsers.find(({ id }) => id === userId);
          if (user) {
            return user.email;
          }
          return undefined;
        });

      const invitedUsers = invitations
        .filter((invite) => Object.values(invite.roles).flat().includes(siteId))
        .map((invite) => invite.email);

      return tenantUsers.filter(
        (user) =>
          user.email &&
          user.enabled &&
          !siteUsers.includes(user.email) &&
          !invitedUsers.includes(user.email)
      );
    }
    return [];
  }, [siteMembers, tenantUsers, invitations]);

  const validateFields = (formData) => {
    const hasEmail = !!formData?.email?.length;

    setSubmitDisabled(!hasEmail || !formData.role);
  };

  useEffect(() => {
    if (!loadingInvitations && siteId) {
      setSiteFormattedInvitations(
        invitations.reduce((acc, invite) => {
          const currentRoleAndSites = Object.entries(invite.roles).find(([, roleSites]) =>
            roleSites.includes(siteId)
          );
          if (currentRoleAndSites) {
            acc.push({ ...invite, role: currentRoleAndSites[0] });
          }
          return acc;
        }, [])
      );
    }
  }, [loadingInvitations, invitations, siteId]);

  useEffect(() => {
    validateFields(inviteFormData);
  }, [inviteFormData]);

  useEffect(() => {
    if (!loadingUsers && !initialized) {
      dispatch(requestMembers(undefined, true));
    }
  }, [loadingUsers, initialized]);

  const onInviteUser = useCallback(
    (formData) => {
      const existingInvite = invitations.find((invite) => invite.email === formData.email[0]);

      if (existingInvite) {
        dispatch(
          updateInvitation(
            {
              ...existingInvite,
              roles: {
                ...existingInvite.roles,
                [formData.role]: [...(existingInvite.roles[formData.role] || []), siteId],
              },
            },
            existingInvite.id
          )
        );
      } else {
        dispatch(
          createInvitations({
            email: formData.email,
            org: realm,
            roles: {
              [formData.role]: [siteId],
            },
          })
        );
      }

      setDisplayAddInvitationModal(false);
      setInviteFormData(null);
    },
    [realm, invitations]
  );

  const onEditInvitation = useCallback((invitation) => {
    setInviteFormData(invitation);
  }, []);

  const onUpdateInvitation = useCallback(
    (formData, invitationId) => {
      const inviteToUpdate = invitations.find((invite) => invite.email === formData.email[0]);
      let prevSiteRole;
      Object.entries(inviteToUpdate.roles).forEach(([role, siteIds]) => {
        if (siteIds.includes(siteId)) {
          prevSiteRole = role;
        }
      });
      if (prevSiteRole !== formData.role) {
        const siteIndexOfPrevRole = inviteToUpdate.roles[prevSiteRole].findIndex(
          (roleSite) => roleSite === siteId
        );
        const update = {
          ...inviteToUpdate,
          roles: {
            ...inviteToUpdate.roles,
            [prevSiteRole]: [
              ...inviteToUpdate.roles[prevSiteRole].slice(0, siteIndexOfPrevRole),
              ...inviteToUpdate.roles[prevSiteRole].slice(siteIndexOfPrevRole + 1),
            ],
            [formData.role]: [...(inviteToUpdate.roles[formData.role] || []), siteId],
          },
        };
        dispatch(updateInvitation(update, invitationId));
        setInviteFormData(null);
      }
    },
    [invitations]
  );

  const onChangeInvitation = useCallback((formData) => {
    validateFields(formData);
    setInviteFormData(formData);
  }, []);

  const onCloseInvitationModal = useCallback(() => {
    setDisplayAddInvitationModal(false);
    setInviteFormData(null);
  }, []);

  const onDeleteInvitation = useCallback(
    (invitation) => {
      const inviteToUpdate = invitations.find((invite) => invite.email === invitation.email);
      let currentSiteRole;
      let siteCount = 0;
      Object.entries(inviteToUpdate.roles).forEach(([role, siteIds]) => {
        siteCount += siteIds.length;
        if (siteIds.includes(siteId)) {
          currentSiteRole = role;
        }
      });
      if (siteCount === 1) {
        dispatch(deleteInvitation(invitation.id));
      } else {
        const siteIndexOfCurrentRole = inviteToUpdate.roles[currentSiteRole].findIndex(
          (roleSite) => roleSite === siteId
        );
        const update = {
          ...inviteToUpdate,
          roles: {
            ...inviteToUpdate.roles,
            [currentSiteRole]: [
              ...inviteToUpdate.roles[currentSiteRole].slice(0, siteIndexOfCurrentRole),
              ...inviteToUpdate.roles[currentSiteRole].slice(siteIndexOfCurrentRole + 1),
            ],
          },
        };
        dispatch(updateInvitation(update, invitation.id));
      }
    },
    [invitations]
  );

  const onRoleChange = useCallback(
    (role, invitation) => {
      const selectedInvite = invitations.find((i) => i.email === invitation.email);
      let prevSiteRole;
      Object.entries(selectedInvite.roles).forEach(([selectedInviteRole, siteIds]) => {
        if (siteIds.includes(siteId)) {
          prevSiteRole = selectedInviteRole;
        }
      });

      if (prevSiteRole !== role) {
        const siteIndexOfPrevRole = selectedInvite.roles[prevSiteRole].findIndex(
          (roleSite) => roleSite === siteId
        );
        const update = {
          ...selectedInvite,
          roles: {
            ...selectedInvite.roles,
            [prevSiteRole]: [
              ...selectedInvite.roles[prevSiteRole].slice(0, siteIndexOfPrevRole),
              ...selectedInvite.roles[prevSiteRole].slice(siteIndexOfPrevRole + 1),
            ],
            [role]: [...(selectedInvite.roles[role] || []), siteId],
          },
        };

        dispatch(updateInvitation(update, invitation.id));
      }
    },
    [orgMembers, invitations]
  );

  const handleCreatable = useCallback(
    (value) => {
      const emails = value.match(/(?<name>[\w.-]+)@(?<domain>[\w-]+\.[\w-]+)(\.\w+)?/g);

      if (emails) {
        setInviteFormData((prevInviteFormData) => ({
          ...prevInviteFormData,
          email: [...(prevInviteFormData.email || []), ...emails],
        }));
        setCreatedEmails((prevCreateEmail) => [...prevCreateEmail, ...emails]);
      }
    },
    [inviteFormData]
  );

  const validateCreatable = (value) => {
    return !!value.match(/(?<name>[\w.-]+)@(?<domain>[\w-]+\.[\w-]+)(\.\w+)?/g);
  };

  const getRoleOptions = useCallback(
    (invitation) =>
      orgMembers
        ? orgMembers.map((m) => ({
            label: m.type,
            value: m.type,
            onSelect: () => onRoleChange(m.type, invitation),
          }))
        : [],
    [orgMembers, onRoleChange]
  );

  const createSchema = useMemo(() => {
    if (orgMembers) {
      return getInvitationSchema(
        orgMembers,
        validNonInvitedNonSiteTenantUsers,
        createdEmails,
        handleCreatable,
        validateCreatable
      );
    }
    return {};
  }, [orgMembers, validNonInvitedNonSiteTenantUsers, createdEmails]);

  const updateSchema = useMemo(
    () => ({
      ...createSchema,
      title: '',
      properties: {
        ...(createSchema.properties || {}),
        email: {
          ...(createSchema.properties?.email || []),
          type: 'string',
          readonly: true,
        },
      },
    }),
    [createSchema]
  );

  const wizClass = wizardType ? `--${wizardType}` : '';

  return (
    <div className="invitation-tab">
      <Heading
        contentLeft={
          <>
            <Input
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder="Search by email"
              className="user-filter"
            />
            <RoleSelect
              id="role-filter"
              value={roleFilter}
              isMulti={false}
              onChange={setRoleFilter}
              realm={realm}
            />
          </>
        }
        contentRight={
          <Can
            permission="members/Write"
            scope={siteId ? { org: realm, site: siteId } : { org: realm }}
          >
            <Button
              className={`role-descriptions${wizardType ? wizClass : ''}`}
              onClick={() => setDisplayRoleDescriptions(true)}
              activity="secondary"
              design="text"
              icon={
                <div className={`info-icon${wizardType ? wizClass : ''}`}>
                  <Icon icon="abb-information-circle-1" />
                </div>
              }
            >
              Role descriptions
            </Button>
            <Button
              className={`button-create${wizardType ? wizClass : ''}`}
              onClick={() => setDisplayAddInvitationModal(true)}
            >
              Invite user
            </Button>
            {displayAddInvitationModal && (
              <AddInvitationModal
                formData={inviteFormData}
                sites={sites}
                isUpdate={false}
                schema={createSchema}
                uiSchema={getUiSchema(true)}
                submitDisabled={submitDisabled}
                onSubmit={onInviteUser}
                onChange={onChangeInvitation}
                onCloseModal={onCloseInvitationModal}
              />
            )}
          </Can>
        }
      />
      <InvitationList
        formData={inviteFormData}
        invitations={siteFormattedInvitations}
        searchText={searchText}
        roleFilter={roleFilter}
        isLoading={loadingUsers || loadingInvitations}
        schema={updateSchema}
        uiSchema={getUiSchema()}
        onEdit={onEditInvitation}
        onUpdate={onUpdateInvitation}
        onChange={onChangeInvitation}
        onClose={onCloseInvitationModal}
        onDelete={onDeleteInvitation}
        getRoleOptions={getRoleOptions}
      />

      {displayRoleDescriptions && (
        <SimpleModal
          title="Role descriptions"
          className="role-permissions-modal"
          size="s"
          onClose={() => setDisplayRoleDescriptions(false)}
        >
          <PermissionLegend />
        </SimpleModal>
      )}
    </div>
  );
};

export default InvitationsTab;
