import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';

import { Icon, Can } from '@avtjs/react-components';

import { getActiveSite } from '../../bundles/sites';
import { setActiveComponentId } from '../../bundles/application';
import { requestComponents } from '../../bundles/components';
import { requestVisualizations } from '../../bundles/visualizations';
import { getIsContributorOrAdmin } from '../../bundles/auth';

import SiteSetupWizard from './Wizard';

const SettingsView = ({
  match: {
    params: { id: siteId },
  },
}) => {
  const dispatch = useDispatch();
  const site = useSelector(getActiveSite);
  const validSite = site && site.org && site.id;
  const isContributorOrAdmin = useSelector((state) => getIsContributorOrAdmin(state, site));
  const [showWizard, setShowWizard] = useState(false);

  const siteDescriptor = site?.siteId !== 'non-identiq' ? 'identiq ' : '';

  useEffect(() => {
    if (siteId) {
      dispatch(requestComponents(siteId));
      dispatch(requestVisualizations(siteId));
      dispatch(setActiveComponentId(null));
    }
  }, [siteId]);

  if (!validSite) {
    return null;
  }

  if (validSite && !isContributorOrAdmin) {
    return (
      <Redirect
        exact
        to="/sites/"
      />
    );
  }

  return (
    <>
      <div className="item-group">
        <div className="item-group--heading">
          <div>Data</div>
        </div>
        <div className="item-group--items">
          <Can
            permission="integrations/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Integrations"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/integrations/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size="l"
                      icon="abb-link"
                    />
                  </div>
                  <div className="item--text">Integrations</div>
                </div>
                <div className="item--description">
                  {`Integrations enable the mapping and synching of assets and fetching of on-demand data.`}
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="sources/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Sources"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/sources/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size="l"
                      icon="abb-database"
                    />
                  </div>
                  <div className="item--text">Sources</div>
                </div>
                <div className="item--description">
                  {`Sources act as data pipelines, connecting signals from existing databases \
                  to your ${siteDescriptor}site.`}
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="variables/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Signals"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/variables/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="abb-04-variables"
                    />
                  </div>
                  <div className="item--text">Signals</div>
                </div>
                <div className="item--description">
                  {`Manage all signals connected to your ${siteDescriptor}site.`}
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="components/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Components"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/components/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="abb-sld-1"
                    />
                  </div>
                  <div className="item--text">Components</div>
                </div>
                <div className="item--description">
                  {`Components are used to link models, sources, signals, files and other external data.`}
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="statesets/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="States"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/states/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="states"
                    />
                  </div>
                  <div className="item--text">States</div>
                </div>
                <div className="item--description">
                  {
                    'Pre-defined states can be linked to imported signals and act as \
                  signal interpreters.'
                  }
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="visualizations/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Visualizations"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/visualizations/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="arrow-chart"
                    />
                  </div>
                  <div className="item--text">Visualizations</div>
                </div>
                <div className="item--description">
                  {'Manage modular visualizations displayed in the dashboard.'}
                </div>
              </div>
            </NavLink>
          </Can>
        </div>
      </div>

      <div className="item-group">
        <div className="item-group--heading">
          <div>General</div>
        </div>
        <div className="item-group--items">
          <Can
            permission="members/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Site"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/info/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="abb-map"
                    />
                  </div>
                  <div className="item--text">Site</div>
                </div>
                <div className="item--description">
                  {
                    'General site information including site name, customer name, geo-\
                  location, etc.'
                  }
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="members/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Members"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/members/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="abb-group"
                    />
                  </div>
                  <div className="item--text">Members</div>
                </div>
                <div className="item--description">
                  {'Manage site users and their permissions.'}
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="sites/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Layout"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/layout/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="abb-thumbnail-view"
                    />
                  </div>
                  <div className="item--text">Layout</div>
                </div>
                <div className="item--description">
                  {'Manage and customize content for each page.'}
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="models/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Models"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/models/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="abb-object"
                    />
                  </div>
                  <div className="item--text">Models</div>
                </div>
                <div className="item--description">
                  {
                    'Manage 3D CAD models, 3D scans, and 2D files displayed in the \
                  spatial navigator.'
                  }
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="sites/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <div
              className="item"
              onClick={() => setShowWizard(true)}
              title="Wizard"
            >
              <div className="item--header">
                <div className="item--icon">
                  <Icon
                    size={'l'}
                    icon="abb-advanced-settings"
                  />
                </div>
                <div className="item--text">Wizard</div>
              </div>
              <div className="item--description">
                {
                  'Automated site set up and configuration (e.g. import site components \
                and signals in batch).'
                }
              </div>
            </div>
          </Can>

          <Can
            permission="algorithms/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Algorithms"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/algorithms/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="abb-binary-data"
                    />
                  </div>
                  <div className="item--text">Algorithms</div>
                </div>
                <div className="item--description">
                  {'Manage algorithms and create tasks used in data analysis.'}
                </div>
              </div>
            </NavLink>
          </Can>

          <Can
            permission="tags/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <NavLink
              title="Tags"
              exact
              to={{
                pathname: `/sites/${site.id}/admin/tags/`,
                search: window.location.search,
              }}
            >
              <div className="item">
                <div className="item--header">
                  <div className="item--icon">
                    <Icon
                      size={'l'}
                      icon="hashtag"
                    />
                  </div>
                  <div className="item--text">Tags</div>
                </div>
                <div className="item--description">{'Manage document and event tags.'}</div>
              </div>
            </NavLink>
          </Can>
        </div>
      </div>
      {showWizard ? (
        <SiteSetupWizard
          site={site}
          onClose={() => setShowWizard(false)}
        />
      ) : null}
    </>
  );
};

export default SettingsView;
