import React, { memo, useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { Button, Icon, Spinner, Select } from '@avtjs/react-components';
import { VariableSizeList as List, areEqual } from 'react-window';
import { useCallbackRef } from 'use-callback-ref';

import { useClientSize, useEventListener, useThrottledCallback } from '../../../../utils';

const MAX_PREVIEW_WIDTH = 136;
const MAX_PREVIEW_HEIGHT = 1.41421 * MAX_PREVIEW_WIDTH;

const PAGE_FIT = 'page-fit';
const PAGE_WIDTH = 'page-width';

const PAGE_MARGIN = 5;

const ZOOM_MAX = 5;
const ZOOM_MIN = 0.25;

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const availablePercentages = [
  {
    label: 'Actual Size',
    value: 1,
  },
  {
    label: 'Page Fit',
    value: PAGE_FIT,
  },
  {
    label: 'Page Width',
    value: PAGE_WIDTH,
  },
  {
    label: '25%',
    value: 0.25,
  },
  {
    label: '50%',
    value: 0.5,
  },
  {
    label: '75%',
    value: 0.75,
  },
  {
    label: '100%',
    value: 1,
  },
  {
    label: '125%',
    value: 1.25,
  },
  {
    label: '150%',
    value: 1.5,
  },
  {
    label: '200%',
    value: 2,
  },
  {
    label: '300%',
    value: 3,
  },
  {
    label: '400%',
    value: 4,
  },
  {
    label: '500%',
    value: 5,
  },
];

function containZoom(value) {
  return Math.min(ZOOM_MAX, Math.max(ZOOM_MIN, value));
}

const PdfTools = ({ zoomLevel, onSetZoomLevel, options: availableOptions }) => {
  const handleSet = useCallback(
    (item) => {
      onSetZoomLevel(item.value);
    },
    [onSetZoomLevel]
  );
  const values = new Set();
  const options = useMemo(
    () =>
      availableOptions.filter((o) => {
        if (values.has(o.value)) {
          return false;
        }
        values.add(o.value);
        return true;
      }),
    [availableOptions]
  );
  const value = options.find((item) => item.value === zoomLevel) || {
    value: zoomLevel,
    label: `${parseInt(zoomLevel * 100, 10)}%`,
  };
  return (
    <div className="pdf-tools-wrapper">
      <Select
        className="pdf-size"
        value={value}
        onChange={handleSet}
        options={options}
      />
    </div>
  );
};

const PreviewPageRenderer = memo(({ index, data, style }) => {
  const { scale = 1, pageDimensions, currentPageIndex, scrollToIndex } = data;

  const pageLoaderRef = useRef();
  const onRender = useCallback(() => {
    if (pageLoaderRef.current) {
      pageLoaderRef.current.style.display = 'none';
    }
  });

  const clickHandler = () => {
    if (scrollToIndex) {
      scrollToIndex(index);
    }
  };

  return (
    <div
      style={style}
      className={`preview-page preview-page-${index} ${index === currentPageIndex ? 'active' : ''}`}
    >
      <div
        className="page-loader-component"
        style={{
          width: Math.round(pageDimensions[index].width * scale),
          height: Math.round(pageDimensions[index].height * scale),
        }}
        ref={pageLoaderRef}
      >
        <Spinner />
      </div>
      <Page
        {...{ scale }}
        width={pageDimensions[index].width}
        pageIndex={index}
        onClick={clickHandler}
        onRenderSuccess={onRender}
        renderTextLayer={true}
        renderAnnotationLayer={false}
      />
      <div className="page-number">
        <span>{index + 1}</span>
      </div>
    </div>
  );
}, areEqual);

PreviewPageRenderer.displayName = 'PreviewPageRenderer';

const MainPageRenderer = memo(({ index, data, style }) => {
  const { scale = 1, pageDimensions, containerWidth } = data;

  const pageLoaderRef = useRef();
  const onRender = useCallback(() => {
    if (pageLoaderRef.current) {
      pageLoaderRef.current.style.display = 'none';
    }
  }, []);

  return (
    <div style={{ ...style, width: containerWidth }}>
      <div
        className="page-loader-component"
        style={{
          width: Math.round(pageDimensions[index].width * scale),
          height: Math.round(pageDimensions[index].height * scale),
        }}
        ref={pageLoaderRef}
      >
        <Spinner />
      </div>
      <Page
        {...{ scale }}
        width={pageDimensions[index].width}
        pageIndex={index}
        onRenderSuccess={onRender}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
    </div>
  );
}, areEqual);

MainPageRenderer.displayName = 'MainPageRenderer';

const PdfPreview = memo(
  ({
    file,
    setFileInfo,
    activeBookmark,
    setActiveBookmark,
    setBookmarkState,
    fullScreenRef,
    contractedSidebar,
    onStepPrevious,
    onStepNext,
  }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [pageDimensions, setPageDimensions] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [canToggleNext, setCanToggleNext] = useState(false);
    const [error, setError] = useState(false);

    const isZooming = useRef(false);
    const afterZoomChange = useRef(null);
    const zoomPosition = useRef(null);
    const ctrlZoom = useRef({ zoom: 1, x: 0, y: 0 });
    const mainListRef = useRef();
    const mainListOuterRef = useCallbackRef(null, (node) => {
      if (node) {
        node.classList.add('obtrusive-scrollbar', 'custom-scrollbar');
      }
    });
    const mainListInnerRef = useRef();
    const previewListRef = useRef();
    const pageOffsets = useRef([]);
    const maxWidth = useRef(0);

    const [clientSize, clientRef] = useClientSize();

    useEffect(() => {
      if (typeof afterZoomChange.current === 'function') {
        afterZoomChange.current();
        afterZoomChange.current = null;
      }
    }, [zoomLevel]);

    const containerDimensions = useMemo(
      () => ({
        ...clientSize,
        // 70 is pageguide height + margin
        height: clientSize.height ? clientSize.height - 70 : 0,
        // 210 is preview width + preview border + margin
        width: clientSize.width ? clientSize.width - 210 : 0,
      }),
      [clientSize]
    );

    const pageFitZoom = useMemo(() => {
      if (!pageDimensions || !containerDimensions || pageDimensions.main.length < 1) {
        return 1;
      }
      const pw = pageDimensions.main.reduce((s, i) => Math.max(s, i.width), 0);
      const ph = pageDimensions.main.reduce((s, i) => Math.max(s, i.height), 0);
      const { width: sw, height: sh } = containerDimensions;
      return containZoom(Math.min(sh / ph, sw / pw));
    }, [pageDimensions, containerDimensions]);

    const pageWidthZoom = useMemo(() => {
      if (!pageDimensions || !containerDimensions || pageDimensions.main.length < 1) {
        return 1;
      }
      const pw = pageDimensions.main.reduce((s, i) => Math.max(s, i.width), 0);
      const { width: sw } = containerDimensions;
      return containZoom(sw / pw);
    }, [pageDimensions, containerDimensions]);

    const availableOptions = useMemo(
      () =>
        availablePercentages.map((item) => {
          if (item.value === PAGE_WIDTH) {
            return {
              ...item,
              value: pageWidthZoom,
            };
          }
          if (item.value === PAGE_FIT) {
            return {
              ...item,
              value: pageFitZoom,
            };
          }
          return item;
        }),
      [pageWidthZoom, pageFitZoom]
    );

    const scrollToZoomPosition = useCallback((position, factor) => {
      const { x, y, sx, sy, marginX } = position;
      const cx = sx + x;
      const cy = sy + y;
      const left = cx * factor - x - marginX * factor;
      const top = cy * factor - y;

      afterZoomChange.current = () => {
        if (mainListRef.current) {
          mainListRef.current.resetAfterIndex(0);
          mainListRef.current.scrollTo(top);
        }
        if (mainListOuterRef.current) {
          mainListOuterRef.current.scrollTop = top;
          mainListOuterRef.current.scrollLeft = left;
        }
      };
    }, []);

    const updateZoomLevel = useCallback(
      (newZoomLevel) => {
        setZoomLevel((oldValue) => {
          if (oldValue !== newZoomLevel) {
            const factor = newZoomLevel / oldValue;
            const { height, width } = mainListOuterRef.current.getBoundingClientRect();
            const sx = mainListOuterRef.current.scrollLeft;
            const sy = mainListOuterRef.current.scrollTop;
            const marginX = Math.max(
              0,
              (containerDimensions.width - maxWidth.current * oldValue) / 2
            );
            scrollToZoomPosition(
              {
                x: width / 2,
                y: height / 2,
                sx,
                sy,
                marginX,
              },
              factor
            );
            return newZoomLevel;
          }
          return oldValue;
        });
      },
      [scrollToZoomPosition]
    );

    const containerRef = React.useCallback(
      (node) => {
        clientRef(node);
        // eslint-disable-next-line no-param-reassign
        fullScreenRef.current = node;
      },
      [clientRef, fullScreenRef, isFullScreen]
    );

    const simulateZoom = useCallback((x, y, zoom) => {
      if (mainListInnerRef.current) {
        if (x === undefined) {
          mainListInnerRef.current.style.transform = 'translate(0px, 0px) scale(1)';
        } else {
          mainListInnerRef.current.style.transform = `translate(${Math.round(x)}px, ${Math.round(
            y
          )}px) scale(${zoom})`;
        }
      }
    }, []);

    const handleZoom = useCallback(
      (e) => {
        if (isZooming.current) {
          e.preventDefault();
          const maxZoom = ZOOM_MAX / zoomLevel;
          const minZoom = ZOOM_MIN / zoomLevel;

          const { zoom: oldZoom, x: oldX, y: oldY } = ctrlZoom.current;

          let factor = Math.min(1.25, Math.max(0.85, e.deltaY * -0.01 + 1));
          let zoom = oldZoom * factor;

          if (zoom < minZoom) {
            factor = minZoom / oldZoom;
            zoom = minZoom;
          }
          if (zoom > maxZoom) {
            factor = maxZoom / oldZoom;
            zoom = maxZoom;
          }

          const innerRect = mainListInnerRef.current.getBoundingClientRect();
          const dx = Math.round((e.clientX - innerRect.left) * (factor - 1));
          const dy = Math.round((e.clientY - innerRect.top) * (factor - 1));

          const x = oldX - dx;
          const y = oldY - dy;

          ctrlZoom.current = {
            zoom,
            x,
            y,
          };

          simulateZoom(x, y, zoom);

          if (zoomPosition.current === null) {
            const { left, top } = mainListOuterRef.current.getBoundingClientRect();
            const sx = mainListOuterRef.current.scrollLeft;
            const sy = mainListOuterRef.current.scrollTop;
            const marginX = Math.max(
              0,
              (containerDimensions.width - maxWidth.current * zoomLevel) / 2
            );
            zoomPosition.current = {
              x: e.clientX - left,
              y: e.clientY - top,
              sx,
              sy,
              marginX,
            };
          }
        }
      },
      [simulateZoom, zoomLevel]
    );

    const handleKeyDown = useCallback(
      (e) => {
        if (e.ctrlKey && mainListOuterRef.current && !isZooming.current) {
          isZooming.current = true;
          zoomPosition.current = null;
          mainListOuterRef.current.classList.add('zooming');
        }
      },
      [mainListOuterRef]
    );

    const stopZooming = useCallback(() => {
      if (isZooming.current) {
        isZooming.current = false;
        const { zoom } = ctrlZoom.current;
        if (zoom !== 1) {
          const factor = containZoom(zoom * zoomLevel) / zoomLevel;
          scrollToZoomPosition(zoomPosition.current, factor);
          setZoomLevel(zoomLevel * factor);
          mainListOuterRef.current.classList.remove('zooming');
        }
        simulateZoom();
        ctrlZoom.current = {
          zoom: 1,
          x: 0,
          y: 0,
        };
      }
    }, [zoomLevel, simulateZoom, scrollToZoomPosition, setZoomLevel]);

    const maybePreventNativeZoom = useCallback(
      (e) => {
        if (isZooming.current) {
          e.preventDefault();
        }
      },
      [isZooming]
    );

    const handleFullScreenChange = () => setIsFullScreen(() => !!document.fullscreenElement);

    useEventListener('keydown', handleKeyDown);
    useEventListener('wheel', handleZoom, mainListOuterRef.current);
    useEventListener('keyup', stopZooming);
    useEventListener('mouseleave', stopZooming, mainListOuterRef.current);
    useEventListener('mousewheel', maybePreventNativeZoom);
    useEventListener('DOMMouseScroll', maybePreventNativeZoom);
    useEventListener('fullscreenchange', handleFullScreenChange);

    const onKeyDown = (e) => {
      if (e.keyCode === 39 && onStepNext) {
        e.preventDefault();
        e.stopPropagation();
        onStepNext(file.id);
      }
      if (e.keyCode === 37 && onStepPrevious) {
        e.preventDefault();
        e.stopPropagation();
        onStepPrevious(file.id);
      }
    };

    useEffect(() => {
      if (canToggleNext) {
        document.addEventListener('keydown', onKeyDown);
      }
      return () => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }, [canToggleNext]);

    useEffect(() => {
      setBookmarkState({
        bookmarkData: {
          page: currentPageIndex + 1,
        },
      });
    }, [currentPageIndex]);

    const maybeClearActiveBookmark = useCallback(
      (pageNumber) => {
        if (activeBookmark) {
          const { page: activeBookmarkPage } = activeBookmark.bookmarkData;
          if (pageNumber !== activeBookmarkPage) {
            setActiveBookmark(null);
          }
        }
      },
      [activeBookmark]
    );

    const updateCurrentPage = useCallback(
      (pageIndex) => {
        if (pageIndex === currentPageIndex) {
          return;
        }

        maybeClearActiveBookmark(pageIndex + 1);

        setCurrentPageIndex(pageIndex);

        if (previewListRef.current) {
          // If the item is already visible, it won't scroll at all.
          previewListRef.current.scrollToItem(pageIndex);
        }
      },
      [currentPageIndex]
    );

    const scrollToIndex = useCallback(
      (index) => {
        if (mainListRef.current) {
          mainListRef.current.scrollToItem(index, 'start');
          updateCurrentPage(index);
        }
      },
      [currentPageIndex]
    );

    const onMainListScroll = useThrottledCallback(
      ({ scrollOffset, scrollUpdateWasRequested }) => {
        if (scrollUpdateWasRequested) {
          return;
        }

        const atTop = scrollOffset === 0;
        const atBottom =
          (scrollOffset + containerDimensions.height) / zoomLevel >=
          pageOffsets.current[pageCount] - pageDimensions.main[pageCount - 1].height * 0.5;

        if (atTop) {
          updateCurrentPage(0);
        } else if (atBottom) {
          updateCurrentPage(pageCount - 1);
        } else {
          const middle = containerDimensions.height * 0.5;
          const pageNumber = pageOffsets.current.findIndex(
            (po) => (scrollOffset + middle) / zoomLevel < po
          );
          updateCurrentPage(pageNumber - 1);
        }
      },
      500,
      { leading: false, trailing: true },
      [pageOffsets, containerDimensions, pageDimensions, pageCount]
    );

    useEffect(() => {
      if (isLoaded && activeBookmark) {
        const { page: pageStr } = activeBookmark.bookmarkData;
        const pageNumber = parseInt(pageStr, 10);
        scrollToIndex(pageNumber - 1);
      }
    }, [isLoaded, activeBookmark]);

    const previewListOuterRef = useCallback((node) => {
      if (node) {
        node.classList.add('obtrusive-scrollbar', 'custom-scrollbar');
      }
    }, []);

    const onDocumentLoadSuccess = useCallback(
      (pdf) => {
        setPageCount(pdf.numPages);
        setFileInfo({
          type: 'pdf',
          pages: pdf.numPages,
        });
        Promise.all(
          Array.from({ length: pdf.numPages }, (v, i) => i + 1).map(pdf.getPage.bind(pdf))
        ).then((meta) => {
          const dimensions = meta.reduce(
            (acc, page) => {
              const [x1, y1, x2, y2] = page.view;
              const isRotated = page.rotate === 90 || page.rotate === 270;
              const width = isRotated ? y2 - y1 : x2 - x1;
              const height = isRotated ? x2 - x1 : y2 - y1;
              // https://mozilla.github.io/pdf.js/api/draft/module-pdfjsLib-PDFPageProxy.html#pageNumber
              acc.main[page.pageNumber - 1] = {
                width,
                height,
              };
              const pr = Math.min(MAX_PREVIEW_WIDTH / width, MAX_PREVIEW_HEIGHT / height);

              // https://mozilla.github.io/pdf.js/api/draft/module-pdfjsLib-PDFPageProxy.html#pageNumber
              acc.preview[page.pageNumber - 1] = {
                width: width * pr,
                height: height * pr,
              };
              return acc;
            },
            { main: [], preview: [] }
          );
          setPageDimensions(dimensions);
          pageOffsets.current = dimensions.main.reduce(
            (acc, curr) => {
              acc.push([...acc].reverse()[0] + curr.height + PAGE_MARGIN);
              return acc;
            },
            [0]
          );
          maxWidth.current = dimensions.main.reduce((s, i) => Math.max(s, i.width), 0);
          const maxHeight = dimensions.main.reduce((s, i) => Math.max(s, i.height), 0);
          const initialZoom = Math.min(
            containerDimensions.width / maxWidth.current,
            containerDimensions.height / maxHeight
          );
          setZoomLevel(containZoom(initialZoom));
          if (mainListRef.current) {
            mainListRef.current.resetAfterIndex(0);
            afterZoomChange.current = () => {
              mainListRef.current.scrollToItem(currentPageIndex);
            };
          }
          setIsLoaded(true);
          setCanToggleNext(true);
        });
      },
      [containerDimensions]
    );

    const onDocumentLoadError = useCallback((err) => {
      setError(err);
    }, []);

    const getPreviewItemSize = useCallback(
      (idx) => Math.round(pageDimensions.preview[idx].height) + 50,
      [pageDimensions]
    );

    const getMainItemSize = useCallback(
      (idx) => Math.round((pageDimensions.main[idx].height + PAGE_MARGIN) * zoomLevel),
      [pageDimensions, zoomLevel]
    );

    const previewItemData = useMemo(
      () => ({
        currentPageIndex,
        scrollToIndex,
        pageDimensions: pageDimensions && pageDimensions.preview,
      }),
      [scrollToIndex, pageDimensions, currentPageIndex]
    );

    const mainItemData = useMemo(
      () => ({
        scale: zoomLevel,
        pageDimensions: pageDimensions && pageDimensions.main,
        containerWidth: pageDimensions
          ? pageDimensions.main.reduce(
              (s, i) => Math.max(s, i.width * zoomLevel),
              containerDimensions.width
            )
          : 0,
      }),
      [zoomLevel, pageDimensions, containerDimensions]
    );

    return (
      <div
        className={
          contractedSidebar ? 'pdf-preview-component' : 'pdf-preview-component smaller-screens'
        }
        ref={containerRef}
      >
        {!isLoaded && !error && (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        )}
        {error && <div className="pdf-preview-component__error">Failed to load PDF file.</div>}
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          error=""
        >
          {isLoaded && containerDimensions && (
            <>
              <div className="pages-preview">
                <div className="page-guide-pages">
                  {currentPageIndex + 1} / {pageCount}
                </div>
                <List
                  height={containerDimensions.height}
                  width={150}
                  itemCount={pageCount}
                  itemSize={getPreviewItemSize}
                  itemData={previewItemData}
                  ref={previewListRef}
                  outerRef={previewListOuterRef}
                >
                  {PreviewPageRenderer}
                </List>
              </div>
              <div className="main-window">
                <div className="page-guide">
                  <div
                    className="title"
                    title={file.name}
                  >
                    {file.name}
                  </div>
                  <PdfTools
                    zoomLevel={zoomLevel}
                    onSetZoomLevel={updateZoomLevel}
                    options={availableOptions}
                  />
                  <div className="change-file">
                    {onStepPrevious && (
                      <Button
                        activity="secondary"
                        disabled={!canToggleNext}
                        onClick={() => onStepPrevious()}
                        icon={<Icon icon="chevron-left" />}
                        tooltip="Previous"
                        tooltipPosition="bottom"
                      />
                    )}
                    {onStepNext && (
                      <Button
                        activity="secondary"
                        disabled={!canToggleNext}
                        onClick={() => onStepNext()}
                        icon={<Icon icon="chevron-right" />}
                        tooltip="Next"
                        tooltipPosition="bottom"
                      />
                    )}
                  </div>
                </div>
                <List
                  height={containerDimensions.height}
                  width={containerDimensions.width}
                  itemCount={pageCount}
                  itemSize={getMainItemSize}
                  itemData={mainItemData}
                  ref={mainListRef}
                  outerRef={mainListOuterRef}
                  innerRef={mainListInnerRef}
                  onScroll={onMainListScroll}
                >
                  {MainPageRenderer}
                </List>
              </div>
            </>
          )}
        </Document>
      </div>
    );
  },
  (prevProps, props) =>
    prevProps.file.name === props.file.name && prevProps.activeBookmark === props.activeBookmark
);

export default PdfPreview;
