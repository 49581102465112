import React from 'react';

import PageItem from '../PageItem';

const PageList = ({ pages, updatePages, selectPage }) => {
  const movePage = (from, to) => {
    updatePages((existingPages) => {
      if (to >= 0 && to <= existingPages.length - 1) {
        const element = existingPages[from];
        const updatedPages = [...existingPages];
        updatedPages.splice(from, 1);
        updatedPages.splice(to, 0, element);
        return updatedPages;
      }
      return existingPages;
    });
  };

  return (
    <div className="page-list-component">
      {pages.map((page, pageIndex) => (
        <PageItem
          pageIndex={pageIndex}
          {...page}
          key={pageIndex}
          pageCount={pages.length}
          movePage={movePage}
          selectPage={selectPage}
        />
      ))}
    </div>
  );
};

export default PageList;
