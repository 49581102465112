.version-list-item.styled-item .styled-item__header__info .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.version-list-item.styled-item .styled-item__header__info .title.xout {
  text-decoration: line-through;
}
.version-list-item.styled-item .model-version-properties-container {
  display: flex;
}
.version-list-item.styled-item .model-version-properties {
  flex: 1;
}
.version-list-item.styled-item .model-version-thumbnail {
  margin-right: 3.5rem;
  height: 10rem;
  width: 10rem;
  border: 2px solid #b3b3b3;
}
.version-list-item.styled-item .model-version-thumbnail img {
  width: 100%;
}
.version-list-item.styled-item .model-version-thumbnail .missing-thumbnail-text {
  display: flex;
  align-items: center;
  color: #666666;
  height: 100%;
  text-align: center;
}

.dark-theme .version-list-item.styled-item {
  background: rgba(255, 255, 255, 0.05);
}
.dark-theme .version-list-item.styled-item .json-editor-component .prop-table .row,
.dark-theme .version-list-item.styled-item .json-editor-component .key,
.dark-theme .version-list-item.styled-item .json-editor-component .value {
  border: 1px solid #4d4d4d;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9Nb2RlbFZpZXcvY29tcG9uZW50cy9WZXJzaW9uTGlzdEl0ZW0iLCJzb3VyY2VzIjpbIlZlcnNpb25MaXN0SXRlbS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUdFO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBSUY7RUFDRTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQU1OO0VBQ0U7O0FBR0U7QUFBQTtBQUFBO0VBR0UiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICdAYXZ0anMvcmVhY3QtY29tcG9uZW50cy9kaXN0L2NvbG9ycy5zY3NzJztcblxuLnZlcnNpb24tbGlzdC1pdGVtLnN0eWxlZC1pdGVtIHtcbiAgLnN0eWxlZC1pdGVtX19oZWFkZXJfX2luZm8gLnRpdGxlIHtcbiAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG4gICAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7XG5cbiAgICAmLnhvdXQge1xuICAgICAgdGV4dC1kZWNvcmF0aW9uOiBsaW5lLXRocm91Z2g7XG4gICAgfVxuICB9XG5cbiAgICAubW9kZWwtdmVyc2lvbi1wcm9wZXJ0aWVzLWNvbnRhaW5lciB7XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgIH1cblxuICAgIC5tb2RlbC12ZXJzaW9uLXByb3BlcnRpZXMge1xuICAgICAgZmxleDogMVxuICAgIH1cblxuICAgIC5tb2RlbC12ZXJzaW9uLXRodW1ibmFpbCB7XG4gICAgICBtYXJnaW4tcmlnaHQ6IDMuNXJlbTtcbiAgICAgIGhlaWdodDogMTByZW07XG4gICAgICB3aWR0aDogMTByZW07XG4gICAgICBib3JkZXI6IDJweCBzb2xpZCAkR3JleTMwO1xuXG4gICAgICBpbWcge1xuICAgICAgICB3aWR0aDogMTAwJTtcbiAgICAgIH1cblxuICAgICAgLm1pc3NpbmctdGh1bWJuYWlsLXRleHQge1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgICAgICBjb2xvcjogJEdyZXk2MDtcbiAgICAgICAgaGVpZ2h0OiAxMDAlO1xuICAgICAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgICB9XG4gICAgfVxufVxuXG4uZGFyay10aGVtZSB7XG4gIC52ZXJzaW9uLWxpc3QtaXRlbS5zdHlsZWQtaXRlbSB7XG4gICAgYmFja2dyb3VuZDogcmdiYSgjZmZmLCAwLjA1KTtcblxuICAgIC5qc29uLWVkaXRvci1jb21wb25lbnQge1xuICAgICAgLnByb3AtdGFibGUgLnJvdyxcbiAgICAgIC5rZXksXG4gICAgICAudmFsdWUge1xuICAgICAgICBib3JkZXI6IDFweCBzb2xpZCAkR3JleTcwO1xuICAgICAgfVxuICAgIH1cbiAgfVxufVxuIl19 */