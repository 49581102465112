import React, { useMemo } from 'react';
import { Input } from '@avtjs/react-components';

import SelectPollingDateRange from '../../SelectPollingDateRange';
import { useFilterText } from './EventTimelineState';
import EventFilters from './EventFilters';

const EventTimelineFilters = ({
  panelId,
  eventTypes: defaultEventTypes,
  enableGlobalDates = true,
  availableIotEventTypes,
  availableUserEventTypes,
  site,
  mode,
}) => {
  const [filterText, onChangeFilterText] = useFilterText('');
  const filterValue = useMemo(() => filterText || '', [filterText]);

  return (
    <div className="event-timeline-filters">
      {panelId && (
        <SelectPollingDateRange
          panelId={panelId}
          enableGlobalDates={enableGlobalDates}
        />
      )}
      <div className="event-timeline-search">
        <Input
          type="text"
          placeholder="Search..."
          value={filterValue}
          onChange={onChangeFilterText}
        />
      </div>
      <EventFilters
        panelId={panelId}
        defaultEventTypes={defaultEventTypes}
        availableIotEventTypes={availableIotEventTypes}
        availableUserEventTypes={availableUserEventTypes}
        site={site}
        mode={mode}
      ></EventFilters>
    </div>
  );
};

export default EventTimelineFilters;
