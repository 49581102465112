import React from 'react';
import { colors } from '@avtjs/react-components';
import { USER_EVENTS } from '../../../constants';
import { useZScale } from './EventTimelineState';

/**
 * Indicates the severity or event state with color
 * @param {{
 *   eventState: 'pending' | 'todo' | 'open' | 'overdue' | 'closed',
 *   severity: 'critical' | 'warning' | 'minor' | 'info' | 'internal',
 *   size: number,
 *   round: boolean,
 * }} props
 */
const Indicator = ({ eventState, severity, size = 8, strokeWidth = 2, round }) => {
  let fill = colors.StatusBlue;
  let stroke = colors.StatusBlue;
  const radius = round ? size / 2 : 1;
  const eventStateMeta = USER_EVENTS.find((x) => x.id === eventState);
  const zScale = useZScale();
  if (severity) {
    fill = zScale(severity);
    stroke = zScale(severity);
  }
  if (eventState) {
    if (eventStateMeta) {
      fill = eventStateMeta.color;
      stroke = eventStateMeta.border;
    }
  }

  return (
    <svg
      width={size + 2 * strokeWidth}
      height={size + 2 * strokeWidth}
      className="severity-indicator"
    >
      <rect
        x={strokeWidth}
        y={strokeWidth}
        rx={radius}
        ry={radius}
        width={size}
        height={size}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
export default Indicator;
