import { Icon } from '@avtjs/react-components';
import React from 'react';

import { severityWeight } from './EventTimelineState';
import Indicator from './Indicator';
import Pill from '../../Pill';
import { datetimeString, utcToSite } from '../../../datetimeUtils';
import { SEVERITIES } from '../../../constants';

function format(date, timezone, withMs = true) {
  return datetimeString(utcToSite(date, timezone), timezone, withMs);
}
export const IotBucketTooltip = ({ bucket, timezone }) => (
  <div className="event-timeline-tooltip">
    <p>
      <label className="event-timeline-tooltip__fixed-width-label">From:</label>
      {format(Number(bucket.from), timezone)}
    </p>
    <p>
      <label className="event-timeline-tooltip__fixed-width-label">To:</label>
      {format(Number(bucket.to), timezone)}
    </p>
    <div className="event-timeline-tooltip__severity-groups">
      {[...bucket.numEventsPerType]
        .sort((a, b) => (severityWeight(a.severity) > severityWeight(b.severity) ? -1 : 1))
        .map((type) => (
          <p key={type.severity}>
            <Indicator severity={type.severity} />
            {type.count} {SEVERITIES[type.severity]} event{type.count > 1 ? 's' : ''}
          </p>
        ))}
    </div>
  </div>
);

export const IotEventTooltip = ({ event, timezone }) => (
  <div className="event-timeline-tooltip">
    <p
      className={`event-timeline-tooltip__severity event-timeline-tooltip__severity--${event.severity}`}
    >
      {SEVERITIES[event.severity]}
    </p>
    <p className="event-timeline-tooltip__timestamp">{format(Number(event.timestamp), timezone)}</p>
    <p>{event.message}</p>
  </div>
);
function toTitleCase(str) {
  // eslint-disable-next-line func-names
  return str.toLowerCase().replace(/(?:^|[\s-/])\w/g, function (match) {
    return match.toUpperCase();
  });
}

export const UserEventTooltip = ({ event, timezone, comments }) => {
  const tooltipDetails = [
    {
      label: 'Planned',
      value: event.from && format(event.from, timezone),
    },
    {
      label: 'Executed',
      value: event.executionFrom && format(event.executionFrom, timezone),
    },
    {
      label: 'Assignee',
      value: event.assignedTo && event.assigneeName,
    },
    {
      label: 'Status',
      value: event.state && toTitleCase(event.state),
    },
  ];
  return (
    <div className="event-timeline-tooltip">
      <div className="event-timeline-tooltip__severity">
        <Indicator
          eventState={event.state}
          round
        />
        {event.name}
      </div>
      <div className="event-timeline-tooltip__details">
        {tooltipDetails.map((t, i) => {
          return (
            t.value && (
              <div key={`tooltip-detail-${t.label}-${i}`}>
                <span>{t.label}</span>
                <span>{t.value}</span>
              </div>
            )
          );
        })}
      </div>

      <div className="event-timeline-tooltip__indicators">
        {comments.length > 0 && (
          <div className="event-timeline-tooltip__indicators--comments">
            <Icon icon="abb-comment" />
            <Pill text={comments.length} />
          </div>
        )}
        {event.files.length > 0 && (
          <div className="event-timeline-tooltip__indicators--attachments">
            <Icon icon="attachment" />
            <Pill text={event.files.length} />
          </div>
        )}
      </div>
    </div>
  );
};
