/* eslint-disable no-nested-ternary */
import { getGroupedUserEventOptions } from '../utils';
import { USER_EVENT_STATUSES, USER_EVENT_ACTIVITY } from '../../../../constants';

const getEventSeriesExportSchema = (
  panelId,
  allUsers,
  allTags,
  eventTypes,
  availableIotEventTypes,
  availableUserEventTypes,
  filterParams,
  pickerId
) => {
  const groupedOptions = getGroupedUserEventOptions(availableUserEventTypes);
  const eventTypeDependecies =
    eventTypes === 'all'
      ? {
          eventType: {
            oneOf: [
              {
                properties: {
                  eventType: { enum: ['user'] },
                  assignedTo: {
                    default: filterParams?.user?.assignee,
                    title: 'Assignee',
                    type: 'array',
                    uniqueItems: true,
                    isMulti: true,
                    items: {
                      type: 'string',
                      anyOf: allUsers.map((user) => ({
                        const: user.id,
                        title: user.name,
                      })),
                    },
                  },
                  users: {
                    default: filterParams?.user?.creator,
                    title: 'Creator',
                    type: 'array',
                    uniqueItems: true,
                    isMulti: true,
                    items: {
                      type: 'string',
                      anyOf: allUsers.map((user) => ({
                        const: user.id,
                        title: user.name,
                      })),
                    },
                  },
                  userTypes: {
                    default: filterParams?.user?.type,
                    title: 'Types',
                    type: 'array',
                    uniqueItems: true,
                    isMulti: true,
                    groupedOptions,
                    items: {
                      type: 'string',
                      anyOf: availableUserEventTypes.map((eventType) => ({
                        const: eventType.id,
                        title: eventType.name,
                      })),
                    },
                  },
                  activity: {
                    type: 'string',
                    title: 'Activity',
                    default: filterParams?.user?.activity ? filterParams?.user?.activity : 'all',
                    anyOf: [
                      ...USER_EVENT_ACTIVITY.map((activity) => ({
                        const: activity.toLowerCase(),
                        title: activity,
                      })),
                    ],
                  },
                  status: {
                    default: filterParams?.user?.status,
                    type: 'string',
                    title: 'Status',
                    anyOf: [
                      ...USER_EVENT_STATUSES.map((status) => ({
                        const: status.toLowerCase(),
                        title: status,
                      })),
                    ],
                  },
                  tags: {
                    default: filterParams?.user?.tags,
                    title: 'Tags',
                    type: 'array',
                    uniqueItems: true,
                    isMulti: true,
                    noOptionsMessage: 'No tags found',
                    items: {
                      type: 'string',
                      anyOf: allTags.map((tag) => ({
                        const: tag.id,
                        title: tag.name,
                      })),
                    },
                  },
                },
              },
              {
                properties: {
                  eventType: { enum: ['iot'] },
                  iotTypes: {
                    default: filterParams?.iot?.eventType,
                    title: 'Types',
                    isMulti: true,
                    uniqueItems: true,
                    type: 'array',
                    items: {
                      type: 'string',
                      anyOf: availableIotEventTypes.map((eventType) => ({
                        const: eventType.id,
                        title: eventType.name,
                      })),
                    },
                  },
                  active: {
                    default: true,
                    title: 'Hide inactive events',
                    type: 'boolean',
                  },
                },
              },
            ],
          },
        }
      : eventTypes === 'iot'
        ? {
            eventType: {
              properties: {
                iotTypes: {
                  default: filterParams?.iot?.eventType,
                  title: 'Types',
                  isMulti: true,
                  uniqueItems: true,
                  type: 'array',
                  items: {
                    type: 'string',
                    anyOf: availableIotEventTypes.map((eventType) => ({
                      const: eventType.id,
                      title: eventType.name,
                    })),
                  },
                },
                active: {
                  default: true,
                  title: 'Hide inactive events',
                  type: 'boolean',
                },
              },
            },
          }
        : {
            eventType: {
              properties: {
                assignedTo: {
                  default: filterParams?.user?.assignee,
                  title: 'Assignee',
                  type: 'array',
                  uniqueItems: true,
                  isMulti: true,
                  items: {
                    type: 'string',
                    anyOf: allUsers.map((user) => ({
                      const: user.id,
                      title: user.name,
                    })),
                  },
                },
                users: {
                  default: filterParams?.user?.creator,
                  title: 'Creator',
                  type: 'array',
                  uniqueItems: true,
                  isMulti: true,
                  items: {
                    type: 'string',
                    anyOf: allUsers.map((user) => ({
                      const: user.id,
                      title: user.name,
                    })),
                  },
                },
                userTypes: {
                  default: filterParams?.user?.type,
                  title: 'Types',
                  type: 'array',
                  uniqueItems: true,
                  isMulti: true,
                  groupedOptions,
                  items: {
                    type: 'string',
                    anyOf: availableUserEventTypes.map((eventType) => ({
                      const: eventType.id,
                      title: eventType.name,
                    })),
                  },
                },
                activity: {
                  type: 'string',
                  title: 'Activity',
                  default: filterParams?.user?.activity ? filterParams?.user?.activity : 'all',
                  anyOf: [
                    ...USER_EVENT_ACTIVITY.map((activity) => ({
                      const: activity.toLowerCase(),
                      title: activity,
                    })),
                  ],
                },
                status: {
                  default: filterParams?.user?.status,
                  type: 'string',
                  title: 'Status',
                  anyOf: [
                    ...USER_EVENT_STATUSES.map((status) => ({
                      const: status.toLowerCase(),
                      title: status,
                    })),
                  ],
                },
                tags: {
                  default: filterParams?.user?.tags,
                  title: 'Tags',
                  type: 'array',
                  uniqueItems: true,
                  isMulti: true,
                  noOptionsMessage: 'No tags found',
                  items: {
                    type: 'string',
                    anyOf: allTags.map((tag) => ({
                      const: tag.id,
                      title: tag.name,
                    })),
                  },
                },
              },
            },
          };

  const hasTags = allTags.length;

  const exportContentDependencies =
    eventTypes === 'iot' || availableUserEventTypes.filter((x) => x.task).length === 0
      ? {
          dependencies: {
            exportContent: {
              oneOf: [
                {
                  properties: {
                    exportContent: { enum: ['events'] },
                    exportType: {
                      type: 'string',
                      title: 'Export format',
                      default: 'xlsx',
                      oneOf: [
                        { const: 'xml', title: '.xml' },
                        { const: 'xlsx', title: '.xlsx' },
                      ],
                    },
                    eventType: {
                      type: 'string',
                      title: 'Event group',
                      default:
                        eventTypes === 'all' ? 'iot' : eventTypes === 'user' ? 'user' : 'iot',
                      oneOf:
                        eventTypes === 'all'
                          ? [
                              { const: 'iot', title: 'IoT' },
                              { const: 'user', title: 'User Events' },
                            ]
                          : eventTypes === 'user'
                            ? [{ const: 'user', title: 'User Events' }]
                            : [{ const: 'iot', title: 'IoT' }],
                    },
                  },
                },
              ],
            },
            ...eventTypeDependecies,
          },
        }
      : {
          dependencies: {
            exportContent: {
              oneOf: [
                {
                  properties: {
                    exportContent: { enum: ['events'] },
                    exportType: {
                      type: 'string',
                      title: 'Export format',
                      default: 'xlsx',
                      oneOf: [
                        { const: 'xml', title: '.xml' },
                        { const: 'xlsx', title: '.xlsx' },
                      ],
                    },
                    eventType: {
                      type: 'string',
                      title: 'Event group',
                      default:
                        eventTypes === 'all' ? 'iot' : eventTypes === 'user' ? 'user' : 'iot',
                      oneOf:
                        eventTypes === 'all'
                          ? [
                              { const: 'iot', title: 'IoT' },
                              { const: 'user', title: 'User Events' },
                            ]
                          : eventTypes === 'user'
                            ? [{ const: 'user', title: 'User Events' }]
                            : [{ const: 'iot', title: 'IoT' }],
                    },
                  },
                },
                {
                  properties: {
                    exportContent: { enum: ['statistics'] },
                    taskTypes: {
                      default: filterParams?.user?.type,
                      title: 'Task Types',
                      type: 'array',
                      uniqueItems: true,
                      isMulti: true,
                      items: {
                        type: 'string',
                        anyOf: availableUserEventTypes
                          .filter((x) => x.task)
                          .map((eventType) => ({
                            const: eventType.id,
                            title: eventType.name,
                          })),
                      },
                    },
                  },
                },
              ],
            },
            ...eventTypeDependecies,
          },
        };

  const schema = {
    type: 'object',
    required: ['range', 'exportContent'],
    properties: {
      range: {
        title: 'Time Interval',
        default: null,
        panelId,
        pickerId,
      },
      exportContent: {
        type: 'string',
        title: 'Export Content',
        placeholder: 'Select content',
        oneOf:
          eventTypes === 'iot' || availableUserEventTypes.filter((x) => x.task).length === 0
            ? [{ const: 'events', title: 'Events' }]
            : [
                { const: 'events', title: 'Events' },
                { const: 'statistics', title: 'Statistics' },
              ],
      },
    },
    ...exportContentDependencies,
  };

  if (!hasTags) {
    delete schema.dependencies.exportContent.oneOf[0].eventType?.oneOf[0].properties.tags;
  }

  return schema;
};

const getUiSchema = () => {
  return {
    range: {
      'ui:field': 'dateRangePicker',
    },
  };
};

export { getUiSchema, getEventSeriesExportSchema };
