import React, { memo, useEffect, useMemo, useState, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeList as List, areEqual } from 'react-window';
import { Button, Icon } from '@avtjs/react-components';
import Tippy from '@tippyjs/react/headless';
import AppContext from '../../../AppContext';
import {
  useActiveListEvents,
  useHoverContext,
  useSetEventListOrdering,
} from './EventTimelineState';
import Indicator from './Indicator';
import UserEventStatus from './UserEventStatus';
import { useIsLoading } from '../../LoadingLayer';
import { useScrollBlock, useClientSize } from '../../../utils';
import { datetimeString, utcToSite } from '../../../datetimeUtils';
import { getTimezone } from '../../../bundles/application';

const DurationArrow = ({ down }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    style={{
      transform: down ? 'rotate(180deg)' : '',
    }}
  >
    <rect
      width="8"
      height="1"
      fill="currentcolor"
    />
    <path
      d="M4.35355 1.64645C4.15829 1.45118 3.84171 1.45118 3.64645 1.64645L0.464466 4.82843C0.269204 5.02369 0.269204 5.34027 0.464466 5.53553C0.659728 5.7308 0.976311 5.7308 1.17157 5.53553L4 2.70711L6.82843 5.53553C7.02369 5.7308 7.34027 5.7308 7.53553 5.53553C7.7308 5.34027 7.7308 5.02369 7.53553 4.82843L4.35355 1.64645ZM4.5 7L4.5 2L3.5 2L3.5 7L4.5 7Z"
      fill="currentcolor"
    />
  </svg>
);

const EventListItem = memo(({ index, data, style }) => {
  const {
    onOpenEvent,
    listEvents,
    next,
    limit,
    time,
    height,
    parentWidth,
    format,
    timezone,
    listColumns,
    allUserEventTypes,
  } = data;

  useEffect(() => {
    if (index === listEvents.length) {
      next();
    }
  }, []);

  const item = listEvents[index];
  const [isHovering, setHovering] = useHoverContext(item && item.id);
  const { theme } = useContext(AppContext);
  const refData = ['name', 'remoteSource', 'origin'];
  const textElementRef = refData.map(() => useRef());
  const [hoverStatus, setHover] = useState(
    refData.reduce((acc, refId) => {
      acc[refId] = false;
      return acc;
    }, {})
  );
  const compareSize = () => {
    const editedHoverStatus = hoverStatus;
    const hoverKeys = Object.keys(editedHoverStatus);
    textElementRef.forEach((f, i) => {
      if (f.current) {
        editedHoverStatus[hoverKeys[i]] = f.current.scrollWidth > f.current.clientWidth;
      }
    });
    setHover(editedHoverStatus);
  };

  useEffect(() => {
    window.addEventListener('mouseover', compareSize);

    return () => {
      window.removeEventListener('mouseout', compareSize);
    };
  }, []);

  const formatSiteDatetime = (timestamp, withMs = true) =>
    datetimeString(utcToSite(timestamp, timezone), timezone, withMs);

  if (!item) {
    if (limit) return null;
    return (
      <div
        className="event-timeline-list-item--loading"
        style={{ ...style, height, width: parentWidth }}
        key={index}
      >
        Loading...
      </div>
    );
  }

  const {
    type,
    event: {
      context: { externalData: { severity: externalSeverity } = {} } = {},
      severity,
      active,
      type: userEventType,
      state,
    },
  } = item;

  const start = type === 'user-start';
  const end = type === 'user-end';
  const className = [
    'event-timeline-list-item',
    limit && 'event-timeline-list-item--limited-list',
    severity === 'critical' && 'event-timeline-list-item--critical',
    severity === 'warning' && 'event-timeline-list-item--warning',
    isHovering && 'event-timeline-list-item--hovering',
    onOpenEvent && 'event-timeline-list-item--clickable',
    limit && format === 'narrow' && 'event-timeline-list-item--limited-list-narrow',
  ]
    .filter((id) => id)
    .join(' ');
  const clickProps = onOpenEvent && {
    onClick: () =>
      onOpenEvent({
        event: item.event,
        id: item.id,
        type: item.type === 'iot' ? 'iot' : 'user',
      }),
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  };
  if (limit) {
    return (
      <div
        className="limited-list-wrapper"
        style={style}
        key={index}
        {...clickProps}
      >
        <div className={className}>
          <div className="event-timeline-list-item__upper">
            <div className="event-timeline-list-item__type">
              <Indicator
                severity={severity}
                eventState={state}
                round={type !== 'iot'}
              />
              {type === 'iot' && severity}
              {userEventType &&
                type.includes('user') &&
                allUserEventTypes.find((e) => e.id === userEventType)?.name}
            </div>
            <div className="event-timeline-list-item__date">
              {formatSiteDatetime(item.timestamp)}
            </div>
          </div>
          <div className="event-timeline-list-item__lower">
            <div className="event-timeline-list-item__message">
              <div>
                {start && '[START] '}
                {end && '[END] '}
                {item.event.message || item.event.name}
              </div>
              <div className="event-timeline-list-item__indicator">
                {start && <DurationArrow down={time === 'future'} />}
                {end && <DurationArrow down={time === 'past'} />}
              </div>
            </div>
            {type !== 'iot' && (
              <div className="event-timeline-list-item__status">
                <UserEventStatus event={item.event} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`${className} ${state}`}
      style={style}
      key={index}
      {...clickProps}
    >
      <div
        className="event-timeline-list-item__date"
        style={{ width: '215px' }}
      >
        {formatSiteDatetime(item.timestamp)}
        <div className="event-timeline-list-item__indicator">
          {start && <DurationArrow />}
          {end && <DurationArrow down />}
        </div>
      </div>
      <div
        className="event-timeline-list-item__type"
        style={{ width: '165px' }}
      >
        <Indicator
          severity={severity}
          eventState={state}
          round={type !== 'iot'}
        />
        {type === 'iot' && (externalSeverity || severity)}
        {userEventType &&
          type.includes('user') &&
          allUserEventTypes.find((e) => e.id === userEventType)?.name}
      </div>
      <Tippy
        disabled={!hoverStatus.name}
        placement="bottom"
        offset={[0, 5]}
        onTrigger={() => {
          document.body.classList.add('select-none');
        }}
        onHide={() => {
          document.body.classList.remove('select-none');
        }}
        touch={['hold', 500]}
        render={(attrs) => (
          <div
            id="tooltip"
            className={`tooltip-box theme-${theme}`}
            tabIndex={-1}
            {...attrs}
          >
            {item.event.message || item.event.name}
          </div>
        )}
      >
        <div
          ref={textElementRef[0]}
          className="event-timeline-list-item__message"
        >
          {start && '[START] '}
          {end && '[END] '}
          {item.event.message || item.event.name}
        </div>
      </Tippy>

      {listColumns.includes('active') && (
        <div
          className="event-timeline-list-item__active"
          style={{ width: '82px' }}
        >
          {active && (
            <Icon
              icon="abb-check-mark"
              size="s"
            />
          )}
        </div>
      )}

      {listColumns.includes('sender') && (
        <div
          className="event-timeline-list-item__sender"
          style={{ width: '165px' }}
        >
          {item.event.sender}
        </div>
      )}

      {listColumns.includes('group') && (
        <Tippy
          disabled={!hoverStatus.remoteSource}
          placement="bottom"
          offset={[0, 5]}
          onTrigger={() => {
            document.body.classList.add('select-none');
          }}
          onHide={() => {
            document.body.classList.remove('select-none');
          }}
          touch={['hold', 500]}
          render={(attrs) => (
            <div
              id="tooltip"
              className={`tooltip-box theme-${theme}`}
              tabIndex={-1}
              {...attrs}
            >
              {item.event.remoteSource}
            </div>
          )}
        >
          <div
            ref={textElementRef[1]}
            className="event-timeline-list-item__group"
            style={{ width: '165px' }}
          >
            {item.event.remoteSource}
          </div>
        </Tippy>
      )}

      {listColumns.includes('pointId') && (
        <Tippy
          disabled={!hoverStatus.origin}
          placement="bottom"
          offset={[0, 5]}
          onTrigger={() => {
            document.body.classList.add('select-none');
          }}
          onHide={() => {
            document.body.classList.remove('select-none');
          }}
          touch={['hold', 500]}
          render={(attrs) => (
            <div
              id="tooltip"
              className={`tooltip-box theme-${theme}`}
              tabIndex={-1}
              {...attrs}
            >
              {item.event.origin}
            </div>
          )}
        >
          <div
            ref={textElementRef[2]}
            className="event-timeline-list-item__pointId"
            style={{ width: '165px' }}
          >
            {item.event.origin}
          </div>
        </Tippy>
      )}

      <div
        className="event-timeline-list-item__status"
        style={{ width: '45px' }}
      >
        {type !== 'iot' && <UserEventStatus event={item.event} />}
      </div>
    </div>
  );
}, areEqual);

const EventList = ({
  width,
  height,
  onOpenEvent,
  itemSize = 30,
  limit,
  time,
  format,
  excludeEventId,
  listColumns = [],
  layoutName,
  scoped = true,
  allUserEventTypes,
}) => {
  const timezone = useSelector(getTimezone);
  const [ordering, setOrdering] = useSetEventListOrdering();
  const [events, setEvents] = useState([]);
  const { events: listEvents = [], next, hasNextPage } = useActiveListEvents(100, scoped);
  const loading = useIsLoading();
  const [limitListEvents, setLimitListEvents] = useState([]);
  const [{ width: listWidth }, eventListRef] = useClientSize();
  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    setEvents(() => listEvents.filter((ev) => ev.id !== excludeEventId));
  }, [JSON.stringify(listEvents), excludeEventId]);

  const orderIcons = {
    asc: 'abb-caret-up',
    desc: 'abb-caret-down',
  };

  const listHeaders = useMemo(
    () => [
      ['Date', 'date-column'],
      ['Type', 'type-column'],
      ['Event name', 'message-column'],
      ...listColumns.map((col) => [col.title, `${col.id}-column`]),
    ],
    [listColumns]
  );

  const toggleOrdering = () =>
    setOrdering(() => Object.keys(orderIcons).find((k) => k !== ordering));

  // header + border-bottom = 31
  const availableHeight = limit ? height : height - 31;

  useEffect(() => {
    if (limit) {
      setLimitListEvents(() => ({
        listEvents: events.slice(0, limit),
        onOpenEvent,
        next,
        limit,
        time,
        width,
        height: availableHeight,
        itemSize,
        format,
        timezone,
        allUserEventTypes,
      }));
    }
  }, [
    JSON.stringify(events.slice(0, limit)),
    onOpenEvent,
    next,
    limit,
    time,
    width,
    availableHeight,
    format,
    timezone,
    allUserEventTypes,
  ]);

  const listItemData = useMemo(() => {
    if (!limit) {
      return {
        listColumns: listColumns.map((c) => c.id),
        listEvents: events,
        onOpenEvent,
        next,
        limit,
        time,
        height: availableHeight,
        parentWidth: width,
        timezone,
        allUserEventTypes,
      };
    }
    return [];
  }, [
    listColumns,
    events,
    onOpenEvent,
    width,
    next,
    limit,
    time,
    availableHeight,
    timezone,
    allUserEventTypes,
  ]);

  useEffect(() => {
    if (eventListRef.current && (limit || listItemData.listEvents.length < 19)) {
      eventListRef.current.addEventListener('mouseenter', blockScroll);
      eventListRef.current.addEventListener('mouseleave', allowScroll);
    }
    return () => {
      if (eventListRef.current) {
        eventListRef.current.removeEventListener('mouseenter', blockScroll);
        eventListRef.current.removeEventListener('mouseleave', allowScroll);
      }
    };
  }, [eventListRef.current]);

  const limitLength = Math.min(limit, events.length);
  const eventsLength = events.length + (hasNextPage ? 1 : 0);

  /*
    min width is calculated on the size on the combined size of the following columns:
    date: 185, type: 165, message: 200,  attachment(status): 50;
  */

  const baseWidth = 630;
  const minWidth =
    baseWidth + listColumns.length * 165 - (listColumns.includes('active') ? 120 : 0);

  const eventsList =
    !loading && events.length === 0 ? (
      <div
        className="event-timeline-event-list--empty"
        style={{ height: availableHeight }}
      >
        No events found
      </div>
    ) : (
      <List
        height={availableHeight}
        // eslint-disable-next-line no-nested-ternary
        width={limit || loading ? width : listWidth > minWidth ? listWidth : minWidth}
        itemCount={limit ? limitLength : eventsLength}
        itemSize={itemSize}
        itemData={limit ? limitListEvents : listItemData}
        listHeaders={listHeaders}
        className={layoutName}
      >
        {EventListItem}
      </List>
    );

  if (limit === 0) {
    return (
      <div
        className="event-timeline-event-list--empty"
        style={{ height: availableHeight }}
      >
        No events
      </div>
    );
  }

  return (
    <div
      ref={eventListRef}
      className={`event-timeline-event-list ${limit ? 'limit-list' : ''}`}
      style={{ minWidth: limit ? width : minWidth }}
    >
      {!limit && (
        <div className="event-timeline-event-list-headers">
          {listHeaders.map((header, i) => {
            if (i === 0) {
              return (
                <Button
                  key={header[0]}
                  design="text"
                  type="button"
                  className={`list-header ${header[1]}`}
                  onClick={toggleOrdering}
                >
                  {header[0]}
                  <Icon icon={orderIcons[ordering]} />
                </Button>
              );
            }
            return (
              <div
                key={header[0]}
                className={`list-header ${header[1]}`}
              >
                {header[0]}
              </div>
            );
          })}
          <div className="list-header status-column"></div>
        </div>
      )}
      {eventsList}
    </div>
  );
};

export default EventList;
